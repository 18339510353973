import React from 'react';
import { graphql, Link } from 'gatsby';
import ToHtml from '../components/html';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import { Gimg } from '../components/gimg';
import { Container } from '../components/base';
import { SubHero } from '../layout/subHero';
import { Page } from '../types';
import styled, { css } from 'styled-components';
import { mediaDesktop, colors, mediaMobile, animation } from '../components/setting';
import { Marks } from '../layout/marks';
import { LinkButton, Button } from '../components/button';

type aboutPage = {
	data: {
		datoCmsPage: Page;
		allDatoCmsMark: any;
	};
};

const encode = (data: any) => {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
};

export default ({ data }: aboutPage) => {
	const node = data.datoCmsPage;

	const [state, setState] = React.useState({});
	const [thxText, setThxText] = React.useState(false);

	const handleChange = e => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const handleSubmit = e => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...state,
			}),
		})
			.then(() => setThxText(true))
			.catch(error => alert(error));
	};

	// export default (data) => {
	return (
		<>
			<SubHero data={node} />
			<Container>
				<HelmetDatoCms seo={node.seoMetaTags} />
				<Content>
					<Box>
						<ToHtml tags={node.content} />
						{/* <LinkButton as={Link} style={{ marginTop: '2.5rem' }} to="/galeria/">
							Zobacz galerie
						</LinkButton> */}
						<h3>Masz pytania?</h3>
						{!thxText ? (
							<Form
								name="contact"
								method="post"
								action="/thanks/"
								data-netlify="true"
								data-netlify-honeypot="bot-field"
								onSubmit={handleSubmit}>
								<p hidden>
									<label>
										Nie wypełniaj tego pola:{' '}
										<input name="bot-field" onChange={handleChange} />
									</label>
								</p>

								<TextInput
									required=""
									placeholder="Imię i nazwisko:"
									name="nadawca"
									onChange={handleChange}></TextInput>

								<TextInput
									required=""
									placeholder="Telefon:"
									name="telefon"
									onChange={handleChange}></TextInput>

								<Textarea
									required=""
									placeholder="Treść zapytania:"
									name="treść"
									onChange={handleChange}></Textarea>

								<Button>Wyślij</Button>
							</Form>
						) : (
							<>
								<h5>Dziękujemy za wysłanie zapytania.</h5>
								<p>Opowiem jak najszybciej to możliwe.</p>
							</>
						)}
					</Box>
					<Map>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.4275539949317!2d19.123381316136516!3d50.82324397952849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710b449ee7a7177%3A0xe37e903e3640baa6!2sProgress!5e0!3m2!1spl!2spl!4v1568916551580!5m2!1spl!2spl"
							style={{ border: 0 }}></iframe>
					</Map>
				</Content>
				<Marks data={data.allDatoCmsMark} />
			</Container>
		</>
	);
};

const Content = styled.div`
	@media ${mediaDesktop} {
		min-height: 700px;
		display: flex;
		width: 100%;
		position: relative;
		align-items: center;
		align-items: stretch;
	}
	margin-bottom: 5rem;
`;
const Map = styled.div`
	background: ${colors.gray};
	margin-bottom: 5rem;
	/* height: 100%; */
	position: relative;
	iframe {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 100% !important;
		/* left: 0; */
		bottom: 0;
		height: 100%;
		width: auto;
	}
	@media ${mediaDesktop} {
		iframe {
			width: calc(100% + (100vw - (100% + 500px)) / 2) !important;
		}

		order: 1;
		width: 70%;
	}
	@media ${mediaMobile} {
		min-height: 600px;
		margin: 4rem 0;
	}
`;

const Box = styled.div`
	@media ${mediaDesktop} {
		order: 2;
		width: 500px;
		padding: 0 0 0 6rem;
		position: relative;
		margin-bottom: 5rem;
	}
`;

const Form = styled.form`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
`;

const inputStyle = css`
	outline: none;
	background: #f4f4f4;
	border: 1px solid #eeeeee;
	border-radius: 4px;
	padding: 1.5rem;
	display: block;
	font-size: 16px;
	margin-bottom: 2rem;
	font-family: 'Roboto', Arial, Helvetica, sans-serif;
	transition: all ${animation.fast};

	&:hover {
		background: #f7f7f7;
		box-shadow: 0 1px 15px -7px rgba(0, 0, 0, 0.1);
	}
	&:focus {
		border-color: ${colors.brand};
		background: #fff;
		box-shadow: 0 2px 18px -10px rgba(0, 0, 0, 0.2);
	}
`;

const TextInput = styled.input`
	${inputStyle}
	width: calc(50% - 1rem);
`;
const Textarea = styled.textarea`
	${inputStyle}
	min-height: 200px;
	width: 100%;
`;

export const query = graphql`
	query ContactQuery($slug: String) {
		datoCmsPage(slug: { eq: $slug }) {
			title
			slug
			content
			category {
				slug
				title
			}
			img {
				fixed(width: 1920, imgixParams: { fm: "jpg", auto: "compress" }) {
					...GatsbyDatoCmsFixed
				}
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			gallery {
				alt
				url
				fixed(width: 537, imgixParams: { fm: "jpg", auto: "compress" }) {
					...GatsbyDatoCmsFixed
				}
			}
		}
		allDatoCmsMark {
			nodes {
				name
				img {
					fixed(width: 232, imgixParams: { fm: "jpg", auto: "compress" }) {
						...GatsbyDatoCmsFixed
					}
					url
				}
			}
		}
	}
`;
